window.$ = window.jQuery = require('jquery');

$(document).ready(function() {
    var data = {};
    $('.btn-cart-del').on('click', function (e){
        alert('clicou');
        var idCard = $(this).data('card');
        $.ajax({
            type: "POST",
            url:  SERVER_URL + "/ajax/card-delete/" + idCard,
            headers: {'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')},
            data: data,
            dataType: 'json',
            success: function(response) {
                
                if (response.success) {
                    
                }
            }
        });
    });
});