// Dependencies
// import $ from 'jquery';
// window.$ = window.jQuery = $;

require('./bootstrap');

require('./site/cards');

// window.Vue = require('vue');

// import Vue from 'vue';

// const app = new Vue({
//     el: '#app'
// });